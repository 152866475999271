// spinner
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 330px;

  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: #e6e6e6;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

.mat-spinner {
  background-color: transparent !important;
}

.spacer {
  flex: 1;
}
