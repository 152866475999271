:root {
  --labgo-button-height: 38px;
  --mdc-text-button-label-text-color: var(--labgo-primary);
  --mdc-protected-button-container-color: var(--labgo-primary);
}

.mat-datepicker-dialog {
  position: absolute;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.mat-calendar-body-selected {
  background-color: var(--pwa-primary-button-background-color) !important;
  color: var(--pwa-primary-button-color) !important;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: var(--labgo-primary) !important;
  background-color: var(--pwa-primary-button-background-color) !important;
  color: var(--pwa-primary-button-color) !important;
}

.mdc-button.labgo-btn {
  .mdc-button__label {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  letter-spacing: 0;
  &.mat-mdc-button-base {
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
  }

  &.mat-mdc-button.mat-unthemed {
    --mdc-text-button-label-text-color: var(--labgo-primary);
  }

  &.mat-mdc-raised-button.labgo-btn,
  &.mat-mdc-unelevated-button.labgo-btn,
  &.mat-mdc-stroked-button.labgo-btn {
    height: var(--labgo-button-height);
    border-radius: 8px;

    &.card-btn {
      height: 1.5rem;
      font-size: 12px;
      line-height: 16px;
      border-radius: 4px;
      width: 100px;
      padding: 0 10px;
    }

    img {
      width: 22px;
      height: 22px;
    }
  }

  // default btn
  &.mat-mdc-raised-button,
  &.mat-mdc-unelevated-button {
    font-size: 13px;
    line-height: 130%;
    font-weight: 700;
    font-family: var(--labgo-pwa-font-family);
    border-radius: 8px;
    padding: 0.5rem;
  }

  &.mat-mdc-unelevated-button,
  &.mat-mdc-raised-button {
    background-color: var(--labgo-primary);
    color: #fff;

    &.primary-btn {
      background-color: var(--pwa-primary-button-background-color);
      color: var(--pwa-primary-button-color);
    }

    &.secondary-btn {
      background-color: var(--pwa-secondary-button-background-color);
      color: var(--pwa-secondary-button-color);
    }

    &[disabled] {
      opacity: 0.5;
    }
  }
}

mat-chip-listbox.labgo-chip-list {
  margin: 0;
  padding: 6px 16px;
  .mdc-evolution-chip-set__chips {
    justify-content: flex-start;
    gap: 16px;
  }
  mat-chip-option.labgo-chip {
    background: green;
    height: 32px;
    border-radius: 8px;
    padding: 0px 8px;
    margin: 0;
    background-color: var(--pwa-topbar-color);
    color: var(--pwa-topbar-background-color);
    border: 1px solid var(--pwa-topbar-color);
    &.mat-mdc-chip-selected {
      background-color: var(--pwa-topbar-background-color);
      color: var(--pwa-topbar-color);
    }
  }
}

mat-chip-listbox.labgo-chip-list mat-chip-option.labgo-chip {
  background-color: var(--pwa-topbar-background-color, transparent);
  color: var(--pwa-topbar-color, #000);
  &.mat-mdc-chip-selected {
    border: 1px solid var(--pwa-topbar-color);
  }
}

.labgo-chip.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled)
  .mdc-evolution-chip__text-label,
.labgo-chip.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled)
  .mdc-evolution-chip__checkmark {
  color: var(--pwa-topbar-color);
}
