@mixin frcc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@mixin frbc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@mixin fccc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
