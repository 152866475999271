:root {
  --labgo-pwa-font-family: "Noto Sans", sans-serif;
  --labgo-pwa-label-color: #667085;
}

.italic {
  font-style: italic;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.text-centered {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

h1,
h2,
p {
  font-family: var(--labgo-pwa-font-family);
  margin: 0;
}

h1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 41.6px;
}

h2 {
  font-weight: 700;
  font-size: 22px;
  line-height: 34px;
  text-align: center;
}

p {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.8rem;
  &.xs {
    font-size: 12px;
    line-height: 14.52px;
  }
  &.sm {
    font-size: 12px;
    line-height: 16px;
  }
  &.md {
    font-size: 14px;
    line-height: 20px;
  }
  &.label {
    color: var(--labgo-pwa-label-color);
  }
}

a {
  font-weight: 700;
  font-size: 12px;
  line-height: 14.52px;
  text-decoration: none;
  color: var(--labgo-primary);
  word-wrap: break-word;
  &.md {
    font-size: 14px;
    line-height: 20px;
  }
}
