@use "@angular/material" as mat;

@import "labgo";
@import "core";
@import "mixins";
@import "typography";

@import "_pwa-buttons.scss";
@import "_pwa-cards.scss";
@import "_pwa-sheets.scss";
@import "_pwa-util.scss";

@include mat.core();

$fonts: mat.define-typography-config(
  $font-family: $labgo-font-pwa,
);
@include mat.typography-hierarchy($fonts);

$theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette($md-labgo-primary),
      accent: mat.define-palette($md-labgo-accent),
      warn: mat.define-palette($md-labgo-warn),
    ),
    typography: $fonts,
  )
);
@include mat.all-component-themes($theme);
@include mat.all-legacy-component-themes($theme);

:root {
  --pwa-main-font-color: #141218;
  --pwa-grey-text: #f8f8f8;
  --pwa-content-padding: 1.2rem;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
}
* {
  box-sizing: border-box;
  font-family: var(--labgo-font-pwa);
}

h2,
h3 {
  color: var(--labgo-text-dark);
  font-family: "Outfit", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 33px;
  margin: 0;
}

h5 {
  color: var(--labgo-text-dark);
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
  margin: 0;
}

p {
  color: var(--labgo-text-light);
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}

a {
  color: var(--labgo-primary);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.mat-dialog-container {
  border-radius: 0 !important;
}

.verification-msg {
  color: var(--pwa-main-font-color);
  margin: 15px 0;
}

::ng-deep .mat-dialog-container {
  padding: 5px;
}

.labgo-margin-top {
  margin-top: 1rem;
}

.pwa-dialog-content {
  margin: 0;
  padding: 20px;
  max-height: 100%;
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: horizontal;
    flex: 0 0 2em;
    margin-bottom: 2em;
  }
}

.account {
  margin: 10px auto;
}

.labgo-svg-img {
  fill: var(--labgo-topbar-background);
}

.page-content {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
  form {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    mat-form-field {
      width: 100%;
    }
    .labgo-card {
      padding: var(--pwa-content-padding);
      gap: 0.5rem;
      &:not(:last-of-type) {
        margin-bottom: 2rem;
      }
      &:last-of-type {
        margin-bottom: 1rem;
      }
    }
  }
}

.mat-mdc-dialog-container {
  .mdc-dialog__title {
    display: flex;
    align-items: center;
    h2 {
      font-size: 16px;
      height: 52px;
      line-height: 52px;
      font-weight: 500;
    }
    h5 {
      color: var(--labgo-text-light);
      font-size: 12px;
      height: 52px;
      line-height: 52px;
      font-weight: 400;
      flex: 1;
      text-align: center;
    }
  }
  .mdc-dialog__content {
    max-height: 75vh;
  }
  .mdc-dialog__actions {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #cac4d0;
  }
}

.mdc-dialog .mdc-dialog__content {
  padding: 1rem;
}

.pac-container,
.pac-item {
  line-height: 48px;
  font-size: 16px;
  .pac-item-query {
    font-size: 16px;
  }
  .pac-icon {
    margin-top: 15px;
  }
}
