:root {
  --labgo-primary: #4050b5;
  --labgo-accent: #f3b130;
  --labgo-blue: #2a6dca;
  --labgo-blue-dark: #083a7f;
  --labgo-warn: #e22d78;
  --labgo-warn-dark: #9c1d0e;
  --labgo-info: #6d8fbd;
  --labgo-green: #53c25c;
  --labgo-green-dark: #1f9478;
  --labgo-inactive: #fff;
  --labgo-rejected: rgb(19, 19, 19);
  --labgo-font: "Inter", serif;
  --labgo-font-pwa: "Noto Sans", sans-serif;
  --labgo-text-dark: #262626;
  --labgo-text-light: #616161;
  --labgo-divider: rgba(0, 0, 0, 0.12);
  --labgo-light: #ccc;
  --labgo-dark: #3c3c3b;
  --labgo-darker: #232323;
  --labgo-background: #efefef;
  --labgo-background-light: #ededed;
  --labgo-sidenav-background: #ededed;
  --labgo-background-blue: #0666ff;
  --labgo-box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  --labgo-topbar-background: #fff;
  --labgo-topbar-color: var(--labgo-primary);
}

$labgo-font: var(--labgo-font);
$labgo-font-pwa: var(--labgo-font-pwa);
$labgo-text-dark: #3c3c3b;
$labgo-text-light: var(--labgo-text-light);
$labgo-divider: rgba(0, 0, 0, 0.12);

$labgo-primary: var(--labgo-primary);
$labgo-primary-pwa: var(--pwa-primary-button-background-color, #083a7f);
$labgo-light: #ccc;
$labgo-dark: #3c3c3b;
$labgo-darker: #232323;

$md-labgo-primary: (
  50: #e8eaf6,
  100: #c5cbe9,
  200: #9fa8da,
  300: #7985cb,
  400: #5c6bc0,
  500: #3f51b5,
  600: #394aae,
  700: #3140a5,
  800: #29379d,
  900: #1b278d,
  A100: #c6cbff,
  A200: #939dff,
  A400: #606eff,
  A700: #4757ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-labgo-accent: (
  50: #fef6e6,
  100: #fbe8c1,
  200: #f9d898,
  300: #f7c86e,
  400: #f5bd4f,
  500: #f3b130,
  600: #f1aa2b,
  700: #efa124,
  800: #ed981e,
  900: #ea8813,
  A100: #ffffff,
  A200: #fff2e5,
  A400: #ffd9b3,
  A700: #ffcd99,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-labgo-warn: (
  50: #ffe1e1,
  100: #feb4b4,
  200: #fe8282,
  300: #fe4f4f,
  400: #fd2a2a,
  500: #fd0404,
  600: #fd0303,
  700: #fc0303,
  800: #fc0202,
  900: #fc0101,
  A100: #ffffff,
  A200: #ffefef,
  A400: #ffbcbc,
  A700: #ffa2a2,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
