.labgo-sheet {
  &.labgo-sheet-fullscreen {
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
  }
  .sheet {
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .sheet-header {
    display: flex;
    flex-direction: row;
  }
  .sheet-content {
    img {
      max-width: 80%;
      max-height: 250px;
    }
  }
  .sheet-footer {
    @include frbc;
  }
  .sheet-form {
    width: 100%;
    mat-form-field {
      width: 100%;
    }
  }

  .mat-list-base .mat-list-item .mat-list-text > *,
  .mat-list-base .mat-list-option .mat-list-text > * {
    color: var(--labgo-primary);
    font-weight: 600;
    font-size: 16px;
  }
}
