:host {
  justify-content: start;
  --labgo-card-box-shadow: 0px 15px 24px rgba(0, 0, 0, 0.08);
}

.labgo-card {
  box-sizing: border-box;
  background-color: white;
  padding: 0.5rem;
  border-radius: 8px;
  gap: 0.5rem;
  display: flex;
  box-shadow: var(--labgo-card-box-shadow);
  width: 100%;

  img {
    max-height: 150px;
  }
  .spacer {
  }
}

.labgo-card.fc {
  flex-direction: column;
}

.mat-expansion-panel.labgo-expansion-card {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: white;
  box-shadow: var(--labgo-card-box-shadow) !important;

  .mat-expansion-panel-header-title.labgo-expansion-card-title {
    display: flex;
    gap: 16px;
    mat-icon {
      color: var(--labgo-primary);
      --size: 23px;
      width: var(--size);
      height: var(--size);
      font-size: var(--size);
      line-height: var(--size);
    }
  }

  .labgo-expansion-card-title.loading {
    opacity: 0.7;
  }

  .mat-expansion-panel-header-title.labgo-expansion-card-title.loading
    mat-icon {
    color: grey;
    opacity: 0.2;
  }

  .mat-expansion-panel-content .mat-expansion-panel-body {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .item {
    }
  }
}

.labgo-card.product {
  flex-direction: column;
  .actions {
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .product-img {
    width: 100%;
    height: 80px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.labgo-card.nft {
  box-sizing: border-box;
  background-color: white;
  padding: 48px 40px;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  width: 100%;

  img {
    max-height: 200px;
    max-width: 200px;
    width: 100%;
    align-self: center;
  }

  .description,
  .details {
    display: flex;
    flex-direction: column;
    gap: 9px;
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .details .item {
    display: flex;
    .name {
      flex: 1;
      color: #667085;
    }
  }
}
