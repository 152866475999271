// flex
.frce {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.frcc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.frcb {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.frca {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.frcs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.fccc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fccs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.fcsc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.fc {
  display: flex;
  flex-direction: column;
}

.fr {
  display: flex;
  flex-direction: row;
}

.frsb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.frss {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.fw {
  flex-wrap: wrap;
}

.jss {
  justify-self: flex-start;
}

.ass {
  align-self: flex-start;
}

.ff-100 {
  mat-form-field {
    width: 100%;
  }
}

// margins
.no-m {
  margin: 0 !important;
}
.mb-0-5 {
  margin-bottom: 0.5em !important;
}
.mb-1 {
  margin-bottom: 1em !important;
}
.mb-1-5 {
  margin-bottom: 1.5em !important;
}
.mb-2 {
  margin-bottom: 2em !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-0-5 {
  margin-top: 0.5em !important;
}
.mt-1 {
  margin-top: 1em !important;
}
.mt-1-5 {
  margin-top: 1.5em !important;
}
.mt-2 {
  margin-top: 2em !important;
}
.mt-3 {
  margin-top: 3em !important;
}
.mr-0-5 {
  margin-right: 0.5rem !important;
}
.mr-1 {
  margin-right: 1rem !important;
}
.mr-1-5 {
  margin-right: 1.5rem !important;
}
.mr-2 {
  margin-right: 2rem !important;
}
.ml-0-5 {
  margin-left: 0.5rem !important;
}
.ml-1 {
  margin-left: 1rem !important;
}
.ml-1-5 {
  margin-left: 1.5rem !important;
}
.ml-2 {
  margin-left: 2rem !important;
}
.m-0-5 {
  margin: 0.5rem !important;
}
.m-1 {
  margin: 1rem !important;
}
.m-1-5 {
  margin: 1.5rem !important;
}
.m-2 {
  margin: 2rem !important;
}
.m-0 {
  margin: 0 !important;
}
.my-0-5 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.my-1-5 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.my-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
.mx-0-5 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.mx-1 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.mx-1-5 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}
.mx-2 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.p-0-5 {
  padding: 0.5rem !important;
}
.p-1 {
  padding: 1rem !important;
}
.p-1-2 {
  padding: 1.5rem !important;
}
.p-2 {
  padding: 2rem !important;
}

// colors
.c-primary {
  color: var(--labgo-primary);
}
.c-accent {
  color: var(--labgo-accent);
}
.c-warn {
  color: var(--labgo-warn);
}

// width
.mw-700 {
  max-width: 700px;
}
.mw-360 {
  max-width: 360px;
}
.mw-320 {
  max-width: 320px;
}
.w-100 {
  width: 100%;
}
.align-center {
  align-self: center;
}
// p
.p-center {
  text-align: center;
}
.p-small {
  font-size: 12px;
  line-height: 16px;
}

.shadow {
  box-shadow: 0px 4px 15px rgb(102 102 102 / 10%);
}

.shadow-inv {
  box-shadow: 0px -4px 15px rgb(102 102 102 / 10%);
}

.abs-centered {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gap-0-5 {
  gap: 0.5rem;
}
.gap-1 {
  gap: 1rem;
}
.gap-1-5 {
  gap: 1.5rem;
}
.gap-2 {
  gap: 2rem;
}

.flex-1 {
  flex: 1;
}

.pre-wrap {
  white-space: pre-wrap;
}

.f-wrap {
  flex-wrap: wrap;
}

.self-left {
  align-self: flex-start;
}

.mw-100 {
  max-width: 100%;
}
